/* General styles for the day picker */
.custom-daypicker {
  background: white;
  /* White background */
  border-radius: 8px;

  padding-left: 10px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  --rdp-accent-color: #EB7E39;
  /* Set orange as the accent color */
  --rdp-range_end-date-background-color: #eb7e39;
  display: inline-block;
  /* Ensure calendars don't stack */
  border: 1px solid #EB7E39;
  /* Add border around the entire calendar */

}

/* Header styles */
.custom-daypicker .rdp-caption {
  text-align: center;
  color: #EB7E39;
  font-size: 12px;


}

option {
  padding: 8px;
}

.custom-daypicker .rdp-caption select {
  background: white;
  /* Dropdown background */
  color: #EB7E39;
  /* Dropdown text color */
  border: 1px solid #EB7E39;
  /* Orange border */
  border-radius: 4px;
  /* Rounded corners for dropdown */
  padding: 4px;

}

.custom-daypicker .rdp-caption select option {
  color: #EB7E39;
  /* Option text color */

}

/* Navigation button styling */
.custom-daypicker .rdp-nav button {
  color: #EB7E39;
  /* Orange color for arrows */


}

.custom-daypicker .rdp-nav button:hover {
  color: #EB7E39;
  /* Keep hover state consistent */

}

.custom-daypicker .rdp-nav .rdp-chevron {
  fill: #EB7E39;
  /* Orange chevron */
  width: 14px;
  height: 14px;
  font-size: 14px;

}

.custom-daypicker .rdp-caption select option {
  color: #EB7E39;
  /* Option text color */

}

.custom-daypicker .rdp-dropdown .rdp-years_dropdown {
  color: #EB7E39;
  border: none;

}

.custom-daypicker .rdp-dropdown .rdp-years_dropdown:focus {
  outline: none
}

.custom-daypicker .rdp * {
  outline: none;
  border: 0
}



.custom-daypicker .rdp-dropdowns {
  color: #EB7E39;
  outline: none;
  border: none;
}

.custom-daypicker .rdp-dropdowns:hover :focus {
  color: #EB7E39;
  outline: none;
  border: none;
}

.custom-daypicker .rdp-nav .rdp-button_previous .rdp-button_next .rdp-chevron {
  color: #EB7E39;
}

.custom-daypicker .rdp-nav button {
  color: #EB7E39;
  /* Change arrow icon color */
}

.custom-daypicker .rdp-nav button:hover {
  color: #EB7E39;
  /* Optional: Keep the hover state consistent */
}

.custom-daypicker .rdp-nav button:focus {
  outline: none;
  /* Remove focus outline */
}

.custom-daypicker .rdp-nav .rdp-button svg {
  fill: #EB7E39;
  /* For SVG icons */
  color: #EB7E39;
  /* For text-based icons */
}

.custom-daypicker .rdp-nav .rdp-chevron {
  color: #EB7E39;
  /* If there is a specific chevron class */
}

/* Override the accent color variable */
.custom-daypicker {
  --rdp-accent-color: #EB7E39;
  /* Set orange as the accent color */
}

/* Ensure direct chevron styling as a fallback */
.custom-daypicker .rdp-chevron {
  fill: #EB7E39;
  /* Explicit orange fill color */
}

/* Start of the selected range */
.rdp-range_start .rdp-day_button {
  background-color: #EB7E39 !important;
  /* Orange background */
  color: white !important;
  /* White text */
  border-radius: 50%;
  /* Circular shape */
  font-weight: bold;
  /* Bold text */
}

/* End of the selected range */

/* Today’s date */
.rdp-today .rdp-day_button {
  color: black !important;
  /* White text */
  border-radius: 50%;
  /* Circular shape */
  font-weight: bold;
  /* Bold text */
}

/* Middle of the selected range */
.rdp-range_middle .rdp-day_button {
  background-color: rgba(235, 126, 57, 0.2) !important;
  /* Light orange background */
  color: gray !important;
  /* Gray text */
  border: none !important;
  /* No border for middle dates */
  font-weight: normal;
  /* Regular text weight */
}

.rdp-range_end .rdp-today .rdp-day_button {
  background-color: #EB7E39 !important;
  /* Orange background */
  color: white !important;
  /* White text */
  border-radius: 50%;
  /* Circular shape */
  font-weight: bold;
  /* Bold text */
}


/* Selected day border */
.rdp-selected .rdp-day_button {
  border: 1px solid #EB7E39 !important;
  /* Orange border */
  border-radius: 50%;
  /* Circular shape */
  font-size: 10px;
  box-sizing: border-box;
  /* Prevent layout shift */
}

/* Remove border for middle dates */
.rdp-range_middle .rdp-selected .rdp-day_button {
  border: none !important;
  /* Ensure no border for middle dates */
  color: white;
}

/* Specific styling for "Today" depending on its position in the range */

/* When today is the start of the selected range */
.rdp-range_start .rdp-day_button {
  background-color: #EB7E39 !important;
  /* Orange background */
  color: white !important;
  /* White text */
  font-weight: bold;
  border-radius: 50%;
  /* Circular */
}

/* When today is the end of the selected range */

.rdp-range_middle .rdp-today .rdp-day_button {
  background-color: rgba(235, 126, 57, 0.5) !important;
  /* Lighter orange */
  color: gray !important;
  /* Gray text */
  font-weight: normal;
  /* Regular text weight */
  border: none !important;
  /* No border */
}

/* When today is outside the range, reset styles */
.rdp-today .rdp-day_button {
  background-color: white !important;
  /* White background */
  color: black !important;
  /* Black text */
  font-weight: normal !important;
  /* Regular weight */
}

/* If today is part of the selected range but not the start or end, ensure it's not highlighted */
.rdp-day_button.rdp-today.rdp-selected {
  /* background-color: rgba(235, 126, 57, 0.2) !important; Light orange */
  color: gray !important;
  font-weight: normal !important;
  /* Regular text weight */
}

.rdp-range_end .rdp-today .rdp-day_button {
  background-color: #EB7E39 !important;
  /* Orange background */
  color: white !important;
  /* White text */
  font-weight: bold;
  border-radius: 50%;
  /* Circular */
}



/* Ensure the months are displayed side by side */
.custom-daypicker .rdp-months {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 130%;

}

/* Set each month to take equal width */
.custom-daypicker .rdp-month {
  display: inline-block;
}

.custom-daypicker .DayPicker {
  outline: none;
  /* Removes the focus outline */
}

.custom-daypicker .DayPicker:focus {
  outline: none;
  /* Ensures no focus outline appears on focus */
}


/* Make sure the overall DayPicker container is flex */
.custom-daypicker .rdp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Ensure the calendar doesn't overflow */
.custom-daypicker .rdp {
  width: 100%;
  box-sizing: border-box;
  /* Prevent overflow */
}

.rdp-dropdown .rdp-months_dropdown:focus {
  outline: none;
}

.rdp-dropdown .rdp-months_dropdown select {
  border: none;
}

/* Add padding to the dropdown options */
.custom-daypicker .rdp-caption select option {
  color: #EB7E39;
  /* Option text color */
  padding: 8px 12px;
  /* Add padding to options for better spacing */
  font-size: 10px;
}

/* Remove black border when the select dropdown is focused */
.custom-daypicker .rdp-caption select:focus {
  outline: none;
  /* Remove the focus outline */
  border: 1px solid #EB7E39 !important;
  /* Set border color to the same orange */
}

/* Remove the default focus outline (black border) from the select dropdown */
.custom-daypicker .rdp-caption select:focus-visible {
  outline: none;
  /* Remove any visible outline */
  box-shadow: none;
  /* Remove any shadow */
}

/* Add padding to the options inside the Year dropdown */
.custom-daypicker .rdp-dropdown.rdp-years_dropdown option {
  color: #EB7E39;
  /* Option text color */
  padding: 8px 12px;
  /* Add padding to options for better spacing */
}

/* Remove black border when the Year dropdown is focused */
.custom-daypicker .rdp-dropdown.rdp-years_dropdown:focus,
.custom-daypicker .rdp-dropdown_root:focus {
  outline: none;
  /* Remove the focus outline */
  border: 1px solid #EB7E39 !important;
  /* Set border color to the same orange */
}

/* Ensure no black border and no outline when the Year dropdown is focused */
.custom-daypicker .rdp-dropdown.rdp-years_dropdown:focus-visible,
.custom-daypicker .rdp-dropdown_root:focus-visible {
  outline: none;
  /* Remove the visible outline */
  box-shadow: none;
  /* Remove any shadow */
}

/* Remove border from the root dropdown container */
.custom-daypicker .rdp-dropdown_root {
  border: none;
  /* Remove any border */
  outline: none;
  /* Ensure no outline */
}

/* Optional: Adjust the style of the dropdown */
.custom-daypicker .rdp-dropdown.rdp-years_dropdown {
  padding: 8px;

}

/* Add padding to the options inside the Month and Year dropdowns */
.custom-daypicker .rdp-dropdown.rdp-months_dropdown option,
.custom-daypicker .rdp-dropdown.rdp-years_dropdown option {
  color: #EB7E39;
  /* Option text color */
  padding: 8px 12px;
  /* Add padding to options for better spacing */
}

/* Remove black border when the Month or Year dropdown is focused */
.custom-daypicker .rdp-dropdown.rdp-months_dropdown:focus,
.custom-daypicker .rdp-dropdown.rdp-years_dropdown:focus,
.custom-daypicker .rdp-dropdown_root:focus {
  outline: none;
  /* Remove the focus outline */
  border: 1px solid #EB7E39 !important;
  /* Set border color to the same orange */

}

/* Ensure no black border and no outline when the Month or Year dropdown is focused */
.custom-daypicker .rdp-dropdown.rdp-months_dropdown:focus-visible,
.custom-daypicker .rdp-dropdown.rdp-years_dropdown:focus-visible,
.custom-daypicker .rdp-dropdown_root:focus-visible {
  outline: none;
  /* Remove the visible outline */
  box-shadow: none;
  /* Remove any shadow */
}

/* Remove border from the root dropdown container */
.custom-daypicker .rdp-dropdown_root {
  border: none;
  /* Remove any border */
  outline: none;
  /* Ensure no outline */
  font-size: 12px;
}

/* Optional: Adjust the style of the dropdown */
.custom-daypicker .rdp-dropdown.rdp-months_dropdown,
.custom-daypicker .rdp-dropdown.rdp-years_dropdown {
  padding: 8px;
  /* Add padding to the dropdown itself */
}


.rdp-dropdown_root {
  border: none !important;
  /* Ensure no border is applied */
  outline: none !important;
  /* Remove focus outlines */
  box-shadow: none !important;
  /* Remove any shadow */
}

/* Handle hover and focus states */
.rdp-dropdown_root:focus,
.rdp-dropdown_root:hover {
  border: none !important;
  /* Remove border on hover or focus */
  outline: none !important;
  /* Remove any outline on hover or focus */
  box-shadow: none !important;
  /* Ensure shadow is not applied */
}

.dropdown:focus-visible~.caption_label {
  outline: none !important;
  /* Remove the outline completely */
}

/* If today is the start or end of the selected range */
.rdp-range_start .rdp-today .rdp-day_button,
.rdp-range_end .rdp-today .rdp-day_button {
  background-color: #EB7E39 !important;
  /* Orange background */
  color: white !important;
  /* White text */
  font-weight: bold;
  border-radius: 50%;
  /* Circular */
}

/* If today is selected but not at the start or end */
.rdp-range_middle .rdp-today .rdp-day_button {
  background-color: rgba(235, 126, 57, 0.2) !important;
  /* Lighter orange */
  color: gray !important;
  /* Gray text */
  font-weight: normal !important;
  /* Regular text weight */
  border: none !important;
  /* No border */
}

/* If today is NOT part of a selected range, reset it to default */
/* If today is NOT selected, keep it with white background and black text */
.rdp-today .rdp-day_button:not(.rdp-selected) {
  background-color: white !important;
  color: black !important;
  font-weight: normal !important;
}

/* If today IS selected (as start or end date), give it an orange background and white text */
.rdp-today.rdp-selected .rdp-day_button,
.rdp-range_start .rdp-today .rdp-day_button,
.rdp-range_end .rdp-today .rdp-day_button {
  background-color: #EB7E39 !important;
  /* Orange background */
  color: white !important;
  /* White text */
  font-weight: bold !important;
  border-radius: 50%;
  /* Circular */
}

.rdp-range_end .rdp-day_button {
  background-color: #eb7e39 !important;
  color: white !important;
}