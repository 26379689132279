@tailwind base;
@tailwind components;
@tailwind utilities;

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(192, 192, 192);
}
.scroller {
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-color: rgb(192, 192, 192);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-corner {
  background: transparent;
}
*::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 2px rgba(112, 112, 112, 0.3); */
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

body {
  font-family: "Poppins", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.blur {
  filter: blur(20px);
  transition: filter 0.3s ease;
}

.pen-list-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc #f9f9f9; /* Thumb and track colors */
}

.pen-list-scrollbar::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.pen-list-scrollbar::-webkit-scrollbar-thumb {
  background: #ccc; /* Color of the scrollbar thumb */
  border-radius: 0; /* Remove rounded corners for square style */
}

.pen-list-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #aaa; /* Darker thumb on hover */
}

.pen-list-scrollbar::-webkit-scrollbar-track {
  background: #f9f9f9; /* Background of the scrollbar track */
  border-radius: 0; /* Square edges */
}
/* Hide the default checkbox */
.custom-checkbox input {
  display: none;
}

/* Custom checkbox styling */
.custom-checkbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: whi;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* White background */
  border: 2px solid #ccc; /* Light gray border */
  border-radius: 4px; /* Rounded corners */
  transition: all 0.3s ease;
}

/* When the checkbox is checked */
.custom-checkbox input:checked + .checkmark {
  background-color: white; /* White background when checked */
  border: 2px solid #888; /* Darker border when checked */
}

/* Create the gray tick mark inside the checkbox */
.custom-checkbox input:checked + .checkmark::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid gray;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Optional: Add hover effect */
.custom-checkbox:hover .checkmark {
  border-color: #888;
}

body {
  line-height: 0.5 !important;
}

#chart-container {
  line-height: normal;
}

/* Custom checkbox styles */
.custom-checkbox {
  width: 16px; /* Set checkbox size */
  height: 16px;
  border: 2px solid gray; /* Border color */
  background-color: white; /* Default background color */
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  outline: none; /* Remove the default focus outline */
  -webkit-appearance: none; /* Remove default styles */
  -moz-appearance: none;
  appearance: none;
}

/* Checked state (custom checkmark) */
.custom-checkbox:checked {
  background-color: white; /* Keep background white */
  border-color: gray; /* Keep border color gray */
}

/* Custom checkmark appearance when checked */
.custom-checkbox:checked::after {
  content: "\2714"; /* Unicode checkmark */
  font-size: 10px; /* Adjust size of checkmark */
  color: gray; /* Set the checkmark color */
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Ensure perfect centering */
}

/* Focus effect */
.custom-checkbox:focus {
  border-color: gray; /* Border color when focused */
}

/* Disabled state */
.custom-checkbox:disabled {
  background-color: white;
  border-color: #c0c0c0;
  cursor: not-allowed;
}


/* Override Ant Design Tooltip font size */
.ant-tooltip {
  font-size: 10px !important; /* Adjust size as needed */
}
