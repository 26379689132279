.custom-switch .ant-switch {
    height: 13px; /* Adjust the entire height */
    width: 26px; /* Adjust the entire width */
}

.custom-switch .ant-switch-checked {
    background-color: transparent; /* Prevent any default background color */
}

.custom-switch .ant-switch-checked.ant-switch-checked {
    background-color: ''; /* Leave this blank for eventual dynamic colors */
}

.custom-switch .ant-switch-checked:before {
    height: 16px; /* Adjust height of the circular button */
    width: 16px; /* Adjust width of the circular button */
    border-radius: 50%; /* Make it circular */
    background-color: ''; /* Leave it clear to utilize fill styles */
}
.ant-switch-handle{
    height: 10px;
}
.custom-switch .ant-switch:not(.ant-switch-checked):before {
    background-color: ''; /* Leave it clear or define a color */
}